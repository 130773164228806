<template>
  <section class="chart-section">
    <article class="title">
      <h2>Results</h2>
    </article>
    <VChart class="chart" :option="chartOption" autoresize />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as echarts from "echarts/core";
import { GridComponent, TitleComponent, LegendComponent } from "echarts/components";
import { BarChart, LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";

echarts.use([GridComponent, BarChart, LineChart, CanvasRenderer, TitleComponent, LegendComponent]);

export default {
  name: "ResultsComponent",
  components: {
    VChart,
  },
  data() {
    return {
      products: [],
      portrait: 0,
    };
  },
  props: {
    ProductReturnPercentage: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      cogsMenuRecipes: "cogsMenuRecipes",
      POSProductLookup: "POSProductLookup",
      cogsPercentageData: "cogsPercentageData",
      cogsCostData: "cogsCostData",
    }),

    chartOption() {
      let x = {
        type: "category",
        data: this.cogsPercentageData[1],
        nameGap: 150,
        axisLabel: {
          rotate: 45,
          fontSize: 6,
          color: "#3bafda",
        },
      };
      let y = {
        type: "value",
        name: "COGS %",
        nameLocation: "middle",
      };
      let portrait = window.matchMedia("(orientation: portrait)");

      this.portrait;

      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
          },
        },
        legend: {
          orient: "horizontal",
        },
        yAxis: portrait.matches ? x : y,
        xAxis: portrait.matches ? y : x,
        color: [getComputedStyle(document.body).getPropertyValue("--action-colour")],

        series: [
          {
            data: this.cogsPercentageData[0],
            type: "bar",
          },
        ],
      };
    },
  },

  beforeMount() {
    screen.orientation.addEventListener("change", () => {
      this.portrait++;
    });
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.chart-section {
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  background-color: var(--primary-color);
  width: 100%;
  .title {
    position: relative;
    margin: 0;
    background: var(--background-fade);
    height: 6rem;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 10px 10px 0 0;
    margin: 0 0 1rem 0;
    h2 {
      text-transform: capitalize;
      padding: 2rem;
      margin: 0;
    }
  }
  .chart {
    height: 40rem;
    width: 75%;
  }
}
</style>
