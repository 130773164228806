<template>
  <router-view></router-view>
  <notifications position="bottom right" />
</template>

<script>
export default {
  name: "app",
  async beforeCreate() {
    const token = await window.localStorage.getItem("token");
    this.$store.dispatch("setToken", token);
  },
};
</script>

<style lang="scss">
@import "public/stylesheets/global";
@import "public/stylesheets/buttons";

body {
  background: var(--secondary-colour);
  .vue-notification-group {
    z-index: 111111 !important;

    .vue-notification {
      font-size: 24px;
    }
  }
  #app{
    overflow: hidden;
  }
  
}

:root {
  --primary-colour: #fff;
  --secondary-colour: #f4f4f4;
  --tertiary-colour: #ebeef6;
  --action-colour: #3bafda;
  --text-colour: #2e2f35;
  --secondary-text-colour: #fff;
  --warning-colour: #f44336;
  --banner-colour: #31312f;
  --border-colour: #ecf2f8;
  --hover-colour: #ededed;
  --background-fade: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(252, 252, 252, 1) 100%);
  --box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  --opacity-colour: rgba(255, 255, 255, 0.7);
  --radial-gradient: radial-gradient(circle, #ffffff, #fdfdfd, #fafafb, #f8f8f9, #f5f6f7, #f3f5f6, #f2f3f6, #f0f2f5, #eff1f5, #edf0f6, #eceff6, #ebeef6);
}

:root.dark-theme {
  --primary-colour: #2e2f35;
  --secondary-colour: #444548;
  --tertiary-colour: #707176;
  --action-colour: #3bafda;
  --text-colour: #d8d5d5;
  --secondary-text-colour: #2e2f35;
  --warning-colour: #f44336;
  --banner-colour: #2e2f35;
  --border-colour: #2e2f35;
  --hover-colour: #2e2f35;
  --background-fade: linear-gradient(90deg, rgba(130, 134, 135, 0.3) 25%, rgba(148, 148, 148, 0.3));
  --box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  --opacity-colour: rgba(255, 255, 255, 0.7);
  --radial-gradient: radial-gradient(circle, #707176, #68696d, #5f6064, #57585c, #4f5053, #494a4e, #444548, #3e3f43, #3a3b3f, #36373c, #323338, #2e2f35);
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-colour: #2e2f35;
    --secondary-colour: #444548;
    --tertiary-colour: #707176;
    --action-colour: #3bafda;
    --text-colour: #d8d5d5;
    --secondary-text-colour: #2e2f35;
    --warning-colour: #f44336;
    --banner-colour: #2e2f35;
    --border-colour: #2e2f35;
    --hover-colour: #2e2f35;
    --background-fade: linear-gradient(90deg, rgba(130, 134, 135, 0.5) 25%, rgba(148, 148, 148, 0.5));
    --box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    --opacity-colour: rgba(255, 255, 255, 0.7);
    --radial-gradient: radial-gradient(circle, #707176, #68696d, #5f6064, #57585c, #4f5053, #494a4e, #444548, #3e3f43, #3a3b3f, #36373c, #323338, #2e2f35);
  }

  :root.light-theme {
    --primary-colour: #fff;
    --secondary-colour: #f4f4f4;
    --tertiary-colour: #ebeef6;
    --action-colour: #3bafda;
    --text-colour: #2e2f35;
    --secondary-text-colour: #fff;
    --warning-colour: #f44336;
    --banner-colour: #2e2f35;
    --border-colour: #ecf2f8;
    --hover-colour: #ededed;
    --background-fade: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(252, 252, 252, 1) 100%);
    --box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    --opacity-colour: rgba(255, 255, 255, 0.7);
    --radial-gradient: radial-gradient(circle, #ffffff, #fdfdfd, #fafafb, #f8f8f9, #f5f6f7, #f3f5f6, #f2f3f6, #f0f2f5, #eff1f5, #edf0f6, #eceff6, #ebeef6);
  }
}
</style>
