<template>
  <Authenticated>
    <div class="reports">
      <div class="main-content">
        <section class="submenu">
          <section class="column-container">
            <div class="radio-btn">
              <div>
                <input type="radio" name="salesType" value="sales" v-model="dataType" class="radio-blue" />
                <label for="sales"><h2>Sales</h2></label>
              </div>
              <div>
                <input type="radio" name="salesType" value="count" v-model="dataType" class="radio-blue" />
                <label for="count"><h2>Count</h2></label>
              </div>
            </div>
          </section>
          <div class="site-select column-container">
            <h2 id="heading">Select Site</h2>
            <div v-if="sites.length > 1">
              <select class="options" :value="reportSite" v-model="site">
                <option value="all">all</option>
                <option v-for="site in sites" :value="site" :key="site">
                  {{ site }}
                </option>
              </select>
            </div>
            <div v-else>
              {{ sites[0] }}
            </div>
          </div>
        </section>

        <section class="totals-section">
          <div class="box-shadow">
            <ReportsComponent :dataType="dataType" :site="site" :day="day" :otherDay="otherDay" :period="'today'" />
          </div>
          <div class="box-shadow">
            <ReportsComponent :dataType="dataType" :site="site" :day="week" :otherDay="otherWeek" :period="'week'" />
          </div>
        </section>

        <section class="charts-section">
          <div class="chart">
            <article class="title">
              <h2>{{ dataType }} - {{ site }}</h2>
              <select name="timeframe" id="" v-model="period" class="dropdown">
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
              </select>
            </article>
            <!-- <div class="day-btn">
              <button>
                <font-awesome-icon icon="chevron-left" @click="previous" />
              </button>
              <button>
                <font-awesome-icon icon="chevron-right" @click="next" />
              </button>
            </div> -->
            <LineChart :site="site" :dataType="dataType" :day="current" :otherDay="comparator" :period="period" :chartType="chartType" />
          </div>
          <div class="chart">
            <article class="title">
              <h2>{{ dataType }} for {{ site }}</h2>
            </article>
            <BarChart :site="site" :dataType="dataType" :day="day" :otherDay="otherDay" :period="'day'" />
          </div>
        </section>
      </div>
    </div>
  </Authenticated>
</template>
<script>
import Authenticated from "@/components/_layouts/Authenticated";
import createdMixin from "@/components/_mixins/createdMixin";
import { mapGetters } from "vuex";
import { api } from "@/helpers/api";
import LineChart from "@/components/Reports/LineChart.vue";
import { dayRange } from "@/helpers/time";
import ReportsComponent from "@/components/Reports/ReportsComponent.vue";
import BarChart from "@/components/Reports/BarChart.vue";

export default {
  name: "reports",
  mixins: [createdMixin],
  components: { Authenticated, LineChart, ReportsComponent, BarChart },
  data() {
    return {
      chartType: "bar",
      period: "day",
      reportData: 0,
      renderComponent: true,
      prodcutCount: {},
      site: this.$route.params && this.$route.params.site ? this.$route.params.site : "all",
      dataType: this.$route.params && this.$route.params.dataType ? this.$route.params.dataType : "sales",
      currentDate: new Date(),
      currentDay: [],
      current: dayRange.getToday(),
      comparator: dayRange.getLastWeekDay(),
      day: dayRange.getToday(),
      otherDay: dayRange.getLastWeekDay(),
      week: dayRange.getThisWeek(),
      otherWeek: dayRange.getLastWeek(),
      month: dayRange.getThisMonth(),
      otherMonth: dayRange.getLastMonth(),
      company: localStorage.getItem("company"),
    };
  },

  methods: {
    async getTotals(start, end) {
      if (this.dataType == "sales") {
        return await api.get_sales_total(this.company, this.site, start, end);
      } else {
        return await api.get_count_total(this.company, this.site, start, end);
      }
    },

    async getData(start, end) {
      if (this.dataType == "sales") {
        return await api.get_sales_data(this.company, this.site, start, end);
      } else {
        return await api.get_product_count(this.company, this.site, start, end);
      }
    },

    previous() {
      // console.log(this.current[0], this.comparator);
      console.log("current day = " + this.current + " current comparator = " + this.comparator);

      this.current = dayRange.editDay(-1);
      this.comparator = dayRange.editWeek(-1, this.current);

      console.log("current day = " + this.current + " current comparator = " + this.comparator);
    },

    next() {},

    setReportSite: function(event) {
      // console.log(event);
      this.$store.dispatch("setReportSite", event.target.value);
      // console.log("site", this.reportSite);
      this.report("week_total");
    },
  },
  mounted() {},
  watch: {
    period: function() {
      if (this.period == "day") {
        this.current = this.day;
        this.comparator = this.otherDay;
      } else if (this.period == "week") {
        this.current = this.week;
        this.comparator = this.otherWeek;
      } else if (this.period == "month") {
        this.current = this.month;
        this.comparator = this.otherMonth;
      }
    },

    site: function() {
      this.$router.replace({
        path: "/reports/" + this.site + "/" + this.dataType,
      });
      this.getData();
      this.getTotals();
    },
    dataType: function() {
      this.$router.replace({
        path: "/reports/" + this.site + "/" + this.dataType,
      });
      this.getData();
      this.getTotals();
    },
    currentDay: function() {
      this.getData();
      this.getTotals();
    },
  },
  computed: {
    ...mapGetters({
      sites: "sites",
      reportSite: "reportSite",
    }),
  },
};
</script>
<style scoped lang="scss">
@import "public/stylesheets/layout";
@import "public/stylesheets/wrapper";
@import "public/stylesheets/actions";

.header {
  margin: 1.5rem;
}

.reports {
  display: flex;
  flex-direction: row;
  background-color: var(--primary-colour);
  margin: 2rem;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  .submenu {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 0.5rem;
    border-radius: 10px;
    padding: 0.5rem;
    box-shadow: var(--box-shadow);
    background: var(--secondary-colour);
    h1 {
      text-transform: capitalize;
    }

    .column-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      margin: 0.5rem;
      width: 8rem;
      #heading {
        font-size: 1.5rem;
        margin: 0.5rem;
        text-wrap: nowrap;
        color: var(--text-colour);
      }

      .radio-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.5rem;
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 1rem;
        }
        label {
          margin: 0.5rem;
          h2 {
            margin: 1rem 0;
          }
        }
      }

      .radio-blue {
        appearance: none;
        margin-right: 0.5rem;
        border-radius: 50%;
        transition: all 500ms ease-in-out;
        height: 1rem;
        width: 1rem;
        white-space: nowrap;
        border: 2px solid var(--secondary-colour);
        &:hover {
          cursor: pointer;
        }
        &:checked {
          background-color: var(--action-colour);
          border: 5px solid var(--secondary-colour);
        }
      }
    }

    .options {
      padding: 0.5rem 1rem;
      margin: 0.5rem;
      border-radius: 5px;
      box-shadow: var(--box-shadow);
      text-transform: capitalize;
      background-color: var(--primary-colour);
      border: none;
      color: var(--text-colour);
      #heading {
        font-size: 1.5rem;
        margin: 0.5rem;
      }
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    .totals-section {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      .box-shadow {
        background-color: var(--secondary-colour);
        box-shadow: var(--box-shadow);
        margin: 0.5rem;
        padding: 1rem;
        border-radius: 10px;
        width: 100%;
      }
    }
    .charts-section {
      display: flex;
      flex-direction: column;
      align-items: space-between;
      padding: 1.5rem;
      margin: 0.5rem;
      border-radius: 10px;
      background-color: var(--secondary-colour);
      box-shadow: var(--box-shadow);
      .column-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0.5rem 2rem;
      }
      .row-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
      }
      .chart {
        box-shadow: var(--box-shadow);
        margin: 0.5rem;
        border-radius: 10px;
        background-color: var(--primary-colour);
        .day-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          position: relative;
          margin: 0;
          background-color: var(--primary-colour);
          height: 6rem;
          width: 100%;
          top: 0;
          left: 0;
          border-radius: 10px 10px 0 0;
          margin: 0 0 1rem 0;
          h2 {
            text-transform: capitalize;
            padding: 2rem;
            margin: 0;
          }
          .dropdown {
            margin: 1rem;
            border-radius: 5px;
            padding: 0.5rem 1rem;
            box-shadow: var(--box-shadow);
            background-color: var(--secondary-colour);
            border: none;
            color: var(--text-colour);
          }
          .radio-btn {
            display: flex;
            flex-direction: row;
            margin: 3px;

            .radio-blue {
              appearance: none;
              margin-right: 0.5rem;
              border-radius: 50%;
              transition: all 500ms ease-in-out;
              height: 1rem;
              width: 1rem;
              white-space: nowrap;
              border: 2px solid var(--secondary-colour);
              cursor: pointer;
              &:checked {
                background-color: var(--action-colour);
                border: 5px solid var(--secondary-colour);
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (orientation: portrait) {
  .reports {
    flex-direction: column;
    width: 100vw;
    margin: 0;
    border-radius: 0;

    .main-content {
      .submenu {
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0.5rem;
        .column-container {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          padding: 0.25rem;
          margin: 0.25rem;
          width: 6rem;

          .radio-btn {
            margin: 0 2rem;
            label {
              margin: 0 0.5rem;
              h2 {
                min-width: 2rem;
                margin: 0.5rem 0.5rem;
              }
            }
          }
        }
        .right-align {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
        }
        .site-select {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          padding: 0.25rem;
          margin: 0.25rem;
          #heading {
            min-width: 7rem;
          }
        }
        #heading,
        .options {
          margin: 2px;
          font-size: 1.15rem;
          text-transform: capitalize;
        }
      }

      .totals-section {
        flex-direction: row;
        .box-shadow {
          margin: 1rem 0.25rem;
          .box {
            // width: 96vw;
            margin: 0.5rem;
            padding: 0.25rem;
          }
        }
      }

      .charts-section {
        margin: 0;
        padding: 0;
        .chart {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          background-color: var(--secondary-colour);
          box-shadow: none;
          margin: 0;
          .title {
            background-color: var(--secondary-colour);
            margin: 0;
            width: 100%;
            height: 5rem;
            padding: 0;
            align-items: center;
            flex-wrap: nowrap;
            h2 {
              min-width: 0;
            }
          }
        }
      }
    }
  }
}
</style>
