<template>
  <Authenticated>
    <div class="dashboard">
      <h1>{{ companySettings.company_name }}</h1>
      <section class="container">
        <div class="reports">
          <div class="report-inner">
            <h2>{{ active }} Active Sites</h2>
            <ul>
              <li v-for="site in siteMap" :key="site.site">
                {{ site.site }}
              </li>
            </ul>
          </div>
          <ReportsComponent
            dataType="sales"
            :site="site"
            :day="day"
            :otherDay="otherDay"
            :period="'today'"
            class="report-inner"
          />
          <ReportsComponent
            dataType="count"
            :site="site"
            :day="day"
            :otherDay="otherDay"
            :period="'today'"
            class="report-inner"
          />
        </div>
      </section>
      <section class="box-container">
        <div class="box" v-for="site in siteMap" :key="site" @click="siteChange(site.site)">
          <div class="spaced">
            <h2>{{ site.site }}</h2>
            <font-awesome-icon :icon="['fas', 'magnifying-glass']" class="icon" size="sm"/>
          </div>
          <div class="unit">
            <h3>POS stations: {{ site.stations.pos.length }}</h3>
            <a href="/pos" v-if="site.stations.pos.length > 0">Interact with you POS</a>
            <a href="/pos" v-else>Setup a POS</a>
          </div>
          <div class="unit">
            <h3>KDS stations: {{ site.stations.kds.length }}</h3>
            <a href="/kds" v-if="site.stations.kds.length > 0">Interact with you KDS</a>
            <a href="/kds" v-else>Setup a KDS</a>
          </div>
        </div>
      </section>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "../_layouts/Authenticated.vue";
import createdMixin from "@/components/_mixins/createdMixin";
import { mapGetters } from "vuex";
import ReportsComponent from "@/components/Reports/ReportsComponent";
import { dayRange } from "@/helpers/time";

export default {
  name: "DashOption",
  components: {
    Authenticated,
    ReportsComponent,
  },
  mixins: [createdMixin],
  props: {
    // Define your props here
  },
  data() {
    return {
      site: "all",
      day: dayRange.getToday(),
      otherDay: dayRange.getLastWeekDay(),
      dataType: "",
      period: "",
      edit: false,
    };
  },
  methods: {
    siteChange(site) {
      this.site = site;
    },
  },
  computed: {
    ...mapGetters({
      siteMap: "siteMap",
      companyName: "companyName",
      siteSettings: "siteSettings",
      reports: "reports",
      companySettings: "companySettings",
    }),

    active() {
      return this.siteMap.length;
    },

    stations() {
      return this.siteMap.filter((site) => site);
    },

    city() {
      return this.siteMap.map;
    },
  },
  mounted() {
    // Lifecycle hook when component is mounted
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 0 1rem;
  overflow: auto;
.container {
  background-color: var(--primary-colour);
  width: 95%;
  height: 30vh;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  padding: 1rem;
  margin: 1rem 0;
  h1 {
    margin: 1rem;
  }
  .reports {
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .report-inner {
      height: 75%;
      width: 33%;
      padding: 2rem;
      margin: 2rem;
      background-color: var(--secondary-colour);
      box-shadow: var(--box-shadow);
      border-radius: 15px;
      ul {
        list-style: none;
        li {
          margin: 0.5rem;
          color: var(--action-colour);
          font-size: larger;
          text-transform: capitalize;
        }
      }
    }
  }
}

.box-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  .box {
    display: flex;
    flex-direction: column;
    width: 33%;
    height: 20rem;
    padding: 1rem;
    margin: 1rem;
    background-color: var(--primary-colour);
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    .spaced {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .icon {
        color: var(--action-colour);
        opacity: 0.5;
      }
    }
    h2 {
      text-transform: capitalize;
      margin: 0.5rem;
    }
    .unit {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      margin: 1rem 0;
      color: var(--action-colour);
      h3 {
        margin: 0.5rem;
      }
      a {
        background-color: var(--secondary-colour);
        padding: 0.75rem;
        margin: 0.5rem;
        border-radius: 10px;
        box-shadow: var(--box-shadow);
        p {
          margin: 0;
        }
        &:click {
          box-shadow: none;
        }
      }
    }
  }
}
}
@media only screen and (orientation: portrait) {
  .dashboard {
    padding: 0;
  }
  .container {
    z-index: 0;
    height: 100vh;
    border-radius: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    .reports {
      flex-direction: column-reverse;
      width: 90%;
      .report-inner {
        width: 85%;
        height: 100%;
        margin: 1rem;
        padding: 1rem;
      }
    }
  }
  .box-container {
    flex-direction: column;
    .box {
      width: 90%;
      height: 100%;
      margin: 1rem 0.5rem;
      padding: 1rem 0.5rem;
      .unit {
        margin: 0;
      }
    }
  }
}
</style>
