<template>
  <div class="banner">
    <Theme />
    <p v-if="!isPortrait">{{ companySettings.company_name }}</p>
    <router-link to="/settings"><font-awesome-icon icon="wrench" size="xl"/></router-link>
  </div>
  <div class="container">
    <Menu />
  </div>
  <div id="content"><slot /></div>
  <div v-if="loading" class="spinner">
    <LoadingSpinner :spinnerMessage="'Retrieving Data...'" />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/_layouts/LoadingSpinner";
import { mapGetters } from "vuex";
import Menu from "@/components/_layouts/Menu";
import Theme from "@/components/Settings/Theme";
export default {
  components: {
    Menu,
    Theme,
    LoadingSpinner,
  },
  name: "Authenticated",
  data() {
    return {
      showDateMenu: false,
      isPortrait: window.matchMedia("(orientation: portrait)").matches,
    };
  },
  methods: {
    handleMenu() {},
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      companyName: "companyName",
      companySettings: "companySettings",
    }),
  },

  mounted() {
    this.$store.dispatch("loading");
    const portrait = window.matchMedia("(orientation: portrait)");
    portrait.onchange = (e) => this.isPortrait = e.matches;
  },
};
</script>

<style lang="scss">
.banner {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
  width: 100%;
  background-color: var(--primary-colour);
  svg {
    padding: 0.5rem;
  }
  p {
    padding: 0.5rem;
    color: var(--text-colour);
  }
  a.router-link-active {
    color: var(--action-colour) !important;
  }
}
.container {
  display: flex;
  top: 0;
  z-index: 1000;
  margin: 3rem 0;
}
#content {
  flex: 1 0;
  margin: 0 0 0 5rem;
  position: relative;
  color: var(--text-colour);
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: var(--opacity-colour);
}

@media (orientation: portrait) {
  .container {
    width: 100%;
  }
  .banner{
    padding: 1rem 0;
    svg:last-child{
      padding-right: 1rem;
    }
  }
  #content {
    margin: 3rem 0 5rem 0;
  }
  a.router-link-active {
    color: var(--action-colour);
  }
}
</style>
