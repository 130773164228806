<template>
  <Authenticated>
    <section class="header">
      <h1>Cost of Goods</h1>
    </section>
    <div class="cogs-container">
      <details>
        <summary @click="rotate(2)">
          <font-awesome-icon icon="caret-right" class="edit blue" id="2" />
          Products
        </summary>
        <section class="prod-section">
          <article class="title">
            <h2>Products</h2>
          </article>
          <div class="cogs-prods">
            <button v-if="!altered" @click="addNewProduct">Add Product</button>
            <button v-if="altered" @click="saveNewProduct">Save Product</button>
            <a href="/" @click.stop.prevent="clearForm" id="left">Clear Form</a>
            <form @submit.prevent.stop="">
              <label for="name">Product Name:</label>
              <input type="text" name="name" v-model="currProduct.value.escaped" />
              <label for="price">Price ($):</label>

              <div class="gst">
                <input type="number" step="any" name="price" id="price" v-model="productPrice" />
                <div class="gst-radio">
                  <p>GST</p>
                  <label for="GST">Incl:</label>
                  <input type="radio" name="GST" :value="true" id="radio" v-model="currProduct.value.productGSTInclusive" />
                </div>
                <div class="gst-radio">
                  <label for="GST">Excl:</label>
                  <input type="radio" name="GST" :value="false" id="radio" v-model="currProduct.value.productGSTInclusive" />
                </div>
              </div>
              <label for="uom">UOM (unit of measure):</label>
              <input list="uoms" name="uom" v-model="unitOfMeasure" />
              <datalist id="uoms">
                <option v-for="unit in cogsUOM" v-bind:key="unit.key">{{ unit.key }}</option>
              </datalist>

              <label for="supplier">Supplier:</label>
              <input list="suppliers" name="supplier" v-model="currProduct.value.name" />
              <datalist id="suppliers">
                <option v-for="supplier in cogsSuppliers" v-bind:key="supplier.key">{{ supplier.key }}</option>
              </datalist>
            </form>
          </div>
          <div class="prod-list">
            <div class="search">
              <input type="search" placeholder="Search Product..." v-model="searchQuery" />
              <h2 v-if="foundProducts.length == 1">{{ foundProducts.length }} result</h2>
              <h2 v-else-if="foundProducts.length > 1">{{ foundProducts.length }} results</h2>
              <h2 v-else>All Products</h2>
              <ul v-if="foundProducts.length !== 0">
                <li v-for="product in foundProducts" :key="product">
                  <a href="/" @click.stop.prevent="loadProduct(product)">{{ product.value.escaped }}</a>
                </li>
              </ul>
              <ul v-if="foundProducts.length == 0">
                <li v-for="product in cogsProducts" v-bind:key="product.key">
                  <a href="/" @click.prevent.stop="loadProduct(product)">{{ product.key }}</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </details>
      <details>
        <summary @click="rotate(1)">
          <font-awesome-icon icon="caret-right" class="edit blue" id="1" />
          Suppliers
        </summary>
        <section class="sups-section">
          <div class="sups-list">
            <article class="title">
              <h2>Suppliers</h2>
            </article>
            <ul>
              <li v-for="supplier in cogsSuppliers" v-bind:key="supplier.key">
                <a href="/" @click.stop.prevent="findProducts(supplier.key)">{{ supplier.key + " (" + supplier.value + " products)" }}</a>
              </li>
            </ul>
          </div>
          <div class="sups-list">
            <article class="title">
              <h2>Products</h2>
            </article>
            <ul v-if="supplierProducts">
              <li v-for="prod in supplierProducts" :key="prod">
                <a href="/" @click.stop.prevent="loadProduct(prod)">{{ prod.value.escaped }}</a>
              </li>
            </ul>
          </div>
        </section>
      </details>
      <details>
        <Summary @click="rotate(3)">
          <font-awesome-icon icon="caret-right" class="edit blue" id="3" />
          Recipes Categories
        </Summary>
        <section class="sups-section">
          <div class="sups-list">
            <article class="title">
              <h2>Categories</h2>
            </article>
            <ul>
              <li v-for="category in cogsRecipeCategories" :key="category">
                <a href="/" @click.stop.prevent="findCategoryRecipes(category.key[0])">{{ category.key[0] }} ({{ category.value }} recipes)</a>
              </li>
            </ul>
          </div>
          <div class="prods-list">
            <article class="title">
              <h2>Recipes in Categories</h2>
            </article>
            <ul>
              <li v-for="recipe in categoryRecipes" :key="recipe">
                <a href="/" @click.stop.prevent="loadRecipe(recipe)">{{ recipe.value.escaped }}</a>
              </li>
            </ul>
          </div>
        </section>
      </details>
      <details>
        <summary @click="rotate(4)">
          <font-awesome-icon icon="caret-right" class="edit blue" id="4" />
          Base Recipes
        </summary>
        <section class="prod-section">
          <article class="title">
            <h2>Base Recipes</h2>
          </article>
          <Recipe :current-recipe="currentRecipe" recipe-type="prep" />
          <div class="prod-list">
            <div class="search">
              <input type="search" placeholder="Search Recipes..." v-model="recipesQuery" />
              <h2>Base Recipes</h2>
              <ul v-if="foundRecipes">
                <li v-for="recipe in foundRecipes" :key="recipe">
                  <a href="/" @click.stop.prevent="loadRecipe(recipe)">{{ recipe.value.escaped }}</a>
                </li>
              </ul>
              <ul v-if="foundRecipes == 0">
                <li v-for="recipe in cogsBaseRecipes" :key="recipe">
                  <a href="/" @click.stop.prevent="loadRecipe(recipe)">{{ recipe.value.escaped }}</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </details>
      <details>
        <summary @click="rotate(5)">
          <font-awesome-icon icon="caret-right" class="edit blue" id="5" />
          Site Menu's
        </summary>
        <section class="prod-section">
          <article class="title">
            <h2>Site Menu's</h2>
          </article>
          <Recipe v-bind:current-recipe="menuRecipe" recipe-type="menu" />
          <div class="site-select">
            <select name="site" id="" v-model="siteSelection">
              <option v-for="site in sites" :key="site">{{ site }}</option>
            </select>
            <details v-for="cat in POSCat" :key="cat">
              <summary class="little">
                {{ cat.key.name }}
              </summary>
              <section>
                <ul>
                  <li v-for="prod in cat.key.products" :key="prod">
                    <span style="display: none;">{{ (check = checkMenuRecipe(prod)) }}</span>
                    <a v-if="check[1] && check[2]" href="/" @click.stop.prevent="loadFoundRecipe(prod)"
                      >{{ check[0] }} <span id="percent">{{ check[2] }}%</span></a
                    >
                    <a v-else-if="check[1] && !check[2]" href="/" @click.stop.prevent="loadFoundRecipe(prod)">{{ check[0] }}</a>
                    <a v-else href="/" @click.stop.prevent="loadMenuRecipe(prod, cat)">{{ check[0] }}</a>
                  </li>
                </ul>
              </section>
            </details>
            <!-- {{ cogsMenuRecipes }} -->
          </div>
        </section>
      </details>
      <div class="prod-section">
        <ResultsComponent :ProductReturnPercentage="ProductReturnPercentage" />
      </div>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "@/components/_layouts/Authenticated";
import createdMixin from "@/components/_mixins/createdMixin";
import Recipe from "@/components/COGS/Recipe.vue";
import ResultsComponent from "@/components/COGS/Results.vue";
import { api } from "@/helpers/api";
import { mapGetters } from "vuex";

export default {
  name: "COGS",
  mixins: [createdMixin],
  components: { Authenticated, Recipe, ResultsComponent },
  emits: ["handleMenu"],
  data() {
    return {
      rotateCaret: false,
      channel: null,
      altered: false,
      productPrice: "",
      unitOfMeasure: "",
      supplierProducts: [],
      searchQuery: "",
      ingredientsQuery: "",
      recipesQuery: "",
      foundIngredients: [],
      foundProducts: [],
      foundRecipes: [],
      categoryRecipes: [],
      foundSubRecipes: [],
      POSProd: {},
      POSCat: {},
      siteSelection: "",
      product: {
        value: {
          name: "",
          escaped: "",
          price_per_uom: "",
          productGSTInclusive: null,
          type: "supplier",
        },
      },
      currProduct: {
        value: {
          name: "",
          escaped: "",
          price_per_uom: "",
          type: "supplier",
          productGSTInclusive: null,
        },
      },
      currentRecipe: {
        value: {
          escaped: "",
          price_per_uom: "",
          name: "",
          type: "prep",
          ingredients: [],
          recipes: [],
        },
      },
      recipe: {
        value: {
          escaped: "",
          price_per_uom: "",
          name: "",
          type: "prep",
          ingredients: [],
          recipes: [],
        },
      },
      recipePrice: "",
      recipeUOM: "",
      menuRecipe: {},
      ProductReturnPercentage: [],
    };
  },
  computed: {
    ...mapGetters({
      cogsSuppliers: "cogsSuppliers",
      cogsProducts: "cogsProducts",
      cogsUOM: "cogsUOM",
      companyName: "companyName",
      cogsSearchProducts: "cogsSearchProducts",
      cogsRecipeCategories: "cogsRecipeCategories",
      cogsBaseRecipes: "cogsBaseRecipes",
      cogsMenuRecipes: "cogsMenuRecipes",
      cogsSearchRecipes: "cogsSearchRecipes",
      findIngredientByID: "findIngredientByID",
      findRecipeByID: "findRecipeByID",
      sites: "sites",
      POSProducts: "POSProducts",
      POSCategories: "POSCategories",
      POSProductLookup: "POSProductLookup",
      cogsCostData: "cogsCostData",
      cogsPercentageData: "cogsPercentageData",
    }),
    currProductPrice() {
      return this.currProduct.value.price_per_uom.split(" ")[0];
    },
    currProductUOM() {
      return this.currProduct.value.price_per_uom.split(" ").slice(-1)[0];
    },
  },
  methods: {
    rotate(id) {
      document.getElementById(id).classList.toggle("rotated");
    },

    checkMenuRecipe(prod) {
      const foundMenuRecipe = this.cogsMenuRecipes.find((recipe) => recipe.value.slug === prod);
      const posFound = this.POSProductLookup({
        site: this.siteSelection,
        prod: prod,
      });
      if (foundMenuRecipe) {
        if ("cost" in foundMenuRecipe.value) {
          let percentage = ((foundMenuRecipe.value.cost / (posFound.value.price / 1.15)) * 100).toFixed(2);
          if (!("percentage" in foundMenuRecipe.value) || foundMenuRecipe.value["percentage"] !== percentage) {
            foundMenuRecipe.value["percentage"] = percentage;
            //api.add_cogs_doc(this.companyName, foundMenuRecipe);
          }
          return [foundMenuRecipe.value.escaped, 1, percentage];
        } else {
          return [foundMenuRecipe.value.escaped, 1, 0];
        }
      } else {
        var name = this.POSProductLookup({
          site: this.siteSelection,
          prod: prod,
        }).value.name;
        return [name, 0];
      }
    },

    updateCogsProducts() {
      this.$store.dispatch("getCogsSuppliers");
      this.$store.dispatch("getCogsProducts");
      this.$store.dispatch("getCogsUOM");
      this.$store.dispatch("getCogsBaseRecipes");
      this.$store.dispatch("getCogsMenuRecipes");
      this.$store.dispatch("getCogsRecipeCategories");
      this.$store.dispatch("getPOSProducts");
    },

    loadProduct(product) {
      this.currProduct = product;
      this.unitOfMeasure = this.currProductUOM;
      this.productPrice = this.currProductPrice;
      this.altered = true;
    },

    dealWithChild(product) {
      console.log(product);
    },

    async addNewProduct() {
      this.currProduct.value.price_per_uom = this.productPrice + " per " + this.unitOfMeasure;
      await api.add_cogs_doc(this.companyName, this.currProduct).then(() => {
        this.updateCogsProducts();
      });
      //this.updateCogsProducts();
      this.currProduct = this.product;
      this.unitOfMeasure = "";
      this.productPrice = "";
      this.altered = true;
      this.searchQuery = "";
    },

    async saveNewProduct() {
      this.currProduct.value.price_per_uom = this.productPrice + " per " + this.unitOfMeasure;
      await api.add_cogs_doc(this.companyName, this.currProduct).then(() => {
        this.updateCogsProducts();
      });
      //api.add_cogs_doc(this.companyName, this.currProduct);
      //this.updateCogsProducts();
      this.altered = false;
      this.unitOfMeasure = "";
      this.productPrice = "";
      this.currProduct = this.product;
      this.searchQuery = "";
    },

    async findProducts(supplier) {
      this.supplierProducts = await api.search_suppliers(JSON.stringify(this.companyName), JSON.stringify(supplier));
    },

    async findCategoryRecipes(recipes) {
      this.categoryRecipes = await api.search_categories(JSON.stringify(this.companyName), JSON.stringify(recipes));
    },

    loadRecipe(recipe) {
      this.currentRecipe = recipe;
      this.recipeUOM = this.currentRecipeUOM;
      this.recipePrice = this.currentRecipePrice;
      this.recipeAltered = true;
    },

    findIngredient() {
      this.foundIngredients = this.cogsProducts.filter((product) => {
        return product.value.escaped.toLowerCase().includes(this.ingredientsQuery.toLowerCase());
      });
    },

    findRecipe() {
      this.foundRecipes = this.cogsBaseRecipes.filter((product) => {
        return product.value.escaped.toLowerCase().includes(this.recipesQuery.toLowerCase());
      });
    },

    queryCheck(query, list) {
      if (query.length > 1) {
        return list(query);
      } else {
        return [];
      }
    },

    loadMenuRecipe(prod, cat) {
      const mrc = this.POSProductLookup({
        site: this.siteSelection,
        prod: prod,
      });
      let menuRecipe = {
        key: {},
        value: {},
      };
      menuRecipe.key = [cat.value.name, mrc.value.name];
      menuRecipe.value.name = cat.value.name;
      menuRecipe.value.escaped = mrc.value.name;
      menuRecipe.value.slug = prod;
      menuRecipe.value.price_per_uom = "";
      menuRecipe.value.price = mrc.value.price;
      menuRecipe.value.type = "menu";
      console.log(this.menuRecipe, cat);
      console.log(mrc);
      this.menuRecipe = menuRecipe;
    },

    loadFoundRecipe(prod) {
      const recipe = this.cogsMenuRecipes.find((recipe) => recipe.value.slug === prod);
      if ("price" in recipe.value) {
        this.menuRecipe = recipe;
      } else {
        const mrc = this.POSProductLookup({
          site: this.siteSelection,
          prod: prod,
        });
        this.menuRecipe = recipe;
        this.menuRecipe.value.price = mrc.value.price;
      }
    },

    clearForm() {
      this.currProduct = this.product;
      this.unitOfMeasure = "";
      this.productPrice = "";
      this.altered = false;
      this.productGSTInclusive = null;
    },
  },

  watch: {
    searchQuery: function() {
      this.foundProducts = this.queryCheck(this.searchQuery, this.cogsSearchProducts);
    },

    ingredientsQuery: function() {
      this.foundIngredients = this.queryCheck(this.ingredientsQuery, this.cogsSearchProducts);
    },

    recipesQuery: function() {
      this.foundRecipes = this.queryCheck(this.recipesQuery, this.cogsSearchRecipes);
    },

    subRecipesQuery: function() {
      this.foundSubRecipes = this.queryCheck(this.subRecipesQuery, this.cogsSearchRecipes);
    },

    siteSelection: function() {
      this.POSProd = this.POSProducts(this.siteSelection);
      this.POSCat = this.POSCategories(this.siteSelection);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin: 1rem;
}
.cogs-container {
  min-height: 90vh;
  padding: 0 1rem;
  h1 {
    margin: 1rem 0.5rem;
  }
  details {
    summary {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .rotated {
        transform: rotate(90deg);
        transition: transform 0.5s;
      }
      .blue {
        color: var(--action-colour);
      }
      .edit {
        margin: 0 0.5rem 0 0;
        font-size: 1.25rem;
        cursor: pointer;
      }
      font-size: 1.25rem;
      box-shadow: var(--box-shadow);
      padding: 1rem;
      border-radius: 5px;
      margin: 0.5rem 1rem;
      cursor: pointer;
      &:active {
        transform: scale(0.99);
      }
    }
    .little {
      font-size: 1rem;
      padding: 0;
      border-radius: 5px;
      margin: 0.25rem;
      box-shadow: none;
      cursor: pointer;
    }
    .sups-section {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: flex-start;
      // background: var(--background-fade);
      margin: 1rem;
      .sups-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 40%;
        background-color: var(--primary-colour);
        border-radius: 10px;
        box-shadow: var(--box-shadow);
        .title {
          position: relative;
          margin: 0;
          background: var(--background-fade);
          height: 6rem;
          width: 100%;
          top: 0;
          left: 0;
          border-radius: 10px 10px 0 0;
          margin: 0 0 1rem 0;
          h2 {
            text-transform: capitalize;
            padding: 2rem;
            margin: 0;
          }
        }
        ul {
          margin-left: 1rem;
          height: 38vh;
          width: 90%;
          overflow: auto;
          padding-left: 1rem;
          li {
            list-style-type: circle;
          }
        }
      }

      .prods-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 40%;
        max-width: 40%;
        background-color: var(--primary-colour);
        border-radius: 10px;
        box-shadow: var(--box-shadow);
        .title {
          position: relative;
          margin: 0;
          background: var(--background-fade);
          height: 6rem;
          width: 100%;
          top: 0;
          left: 0;
          border-radius: 10px 10px 0 0;
          margin: 0 0 1rem 0;
          h2 {
            text-transform: capitalize;
            padding: 2rem;
            margin: 0;
          }
        }
        ul {
          margin-left: 1rem;
          height: 38vh;
          width: 90%;
          overflow: auto;
          padding-left: 1rem;
          li {
            list-style-type: circle;
          }
        }
      }

      .sups-edit {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        max-width: 30%;
        flex-grow: 1;
        padding: 1rem;
        button {
          width: 100%;
          margin: 0.5rem 0;
        }
        form {
          display: flex;
          flex-direction: column;
          width: 100%;
          input {
            width: 94%;
            padding: 0.5rem;
            margin: 0.25rem;
            box-shadow: var(--box-shadow);
            border-radius: 5px;
            border: none;
          }
        }
      }
    }
  }

  .prod-section {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1rem;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    background: var(--primary-colour);
    flex-wrap: wrap;
    .title {
      flex: 0 1 100%;
      position: relative;
      margin: 0;
      background: var(--background-fade);
      height: 6rem;
      width: 100%;
      top: 0;
      left: 0;
      border-radius: 10px 10px 0 0;
      margin: 0 0 1rem 0;
      h2 {
        text-transform: capitalize;
        padding: 2rem;
        margin: 0;
      }
    }

    // max-height: 80vh;
    .cogs-prods {
      min-width: 30rem;
      max-width: 65%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 0 auto;
      margin-bottom: 1rem;
      // padding: 2rem;
      flex-grow: 1;

      form {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 0.5rem 0;
        .gst {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
          .gst-radio {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 30%;
            margin-left: 0.25rem;
            input {
              appearance: none;
              margin: 0 0.5rem;
              border-radius: 50%;
              transition: all 500ms ease-in-out;
              height: 1rem;
              width: 1rem;
              white-space: nowrap;
              border: 2px solid var(--secondary-colour);
              &:checked {
                background-color: var(--action-colour);
                border: 5px solid var(--secondary-colour);
              }
            }
            label {
              width: 20%;
              text-wrap: nowrap;
            }
          }
        }

        #radio {
          width: 1rem;
        }
        #price {
          width: 100%;
          clear: left;
        }
      }
      #left {
        text-decoration: underline;
        width: 95%;
        text-align: left;
        margin-bottom: 1rem;
      }
      button {
        width: 95%;
        margin: 0.5rem 0;
        background-color: var(--action-colour);
        box-shadow: var(--box-shadow);
      }
      form {
        display: flex;
        min-width: 95%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-grow: 1;
        max-height: 80vh;
        overflow: auto;
        input {
          width: 90%;
          padding: 0.5rem;
          margin: 0.25rem;
          box-shadow: var(--box-shadow);
          border-radius: 5px;
          border: none;
        }
        label {
          margin: 0.25rem;
          width: 100%;
          text-align: left;
        }
      }
    }
    .site-select {
      max-height: 75vh;
      min-width: 22rem;
      overflow: auto;
      margin: 0.5rem;
      // padding: 2rem 0;
      select {
        margin-bottom: 1rem;
        padding: 0.75rem;
        border-radius: 3px;
        border: solid 2px var(--action-colour);
        width: 100%;
      }
      details {
        ul {
          width: 100%;
          li {
            list-style-type: circle;
          }
        }
      }
    }
    #percent {
      color: var(--action-colour);
    }
    .prod-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 1rem;
      // padding: 2rem 0;
      min-width: 22rem;
      max-width: 22rem;
      .search {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 22rem;
        padding: 0.5rem;
        h2 {
          margin: 0.5rem;
        }
        input {
          padding: 0.75rem;
          min-width: 100%;
          border: solid 2px var(--action-colour);
          border-radius: 3px;
        }
        ul {
          max-height: 38vh;
          overflow: auto;
          margin: 0;
          li {
            list-style-type: circle;
            a {
              text-decoration: none;
              text-wrap: wrap;
            }
          }
        }
      }
    }
  }
}
@media only screen and (orientation: portrait) {
  .cogs-container {
    // margin: 3rem 0 0 0;
    details {
      .sups-section {
        margin: 5px;
        overflow-x: scroll;
        flex-direction: column;
        .sups-list {
          min-width: 40%;
          max-width: 100%;
          margin: 0.5rem;
          max-height: 20rem;
          ul {
            width: 90%;
            padding-left: 2rem;
          }
        }
        .prods-list {
          min-width: 90%;
          margin: 1rem auto;
          max-height: 20rem;
          ul {
            width: 90%;
            padding-left: 2rem;
          }
        }
      }
      .prod-section {
        flex-direction: column;
        margin: 1rem 1rem;
        .site-select {
          margin: 0.5rem auto;
          min-width: 90%;
        }
        .cogs-prods {
          min-width: 90%;
          margin: 1rem;
          .gst {
            .gst-radio {
              label {
                width: 50%;
              }
            }
          }
        }
        .prod-list {
          margin: 0 auto;
          max-width: 80%;
          min-width: 10%;
          .search {
            min-width: 50%;
          }
        }
        .prods-edit {
          max-width: 90%;
          min-width: 40%;
          margin: 0;
        }
      }
    }
  }
}
</style>
