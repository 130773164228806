import App from "./App.vue";
import Dashboard from "./components/Dashboard/Dashboard.vue";
import DashOption from "./components/Dashboard/DashOption.vue";
import Reports from "./components/Reports/Reports.vue";
import Setup from "./components/Setup/Setup.vue";
import Site from "./components/Setup/Site.vue";
import SiteAdjust from "./components/Settings/SiteAdjust.vue";
import Station from "./components/Setup/Station.vue";
import Settings from "./components/Settings/Settings.vue";
import Login from "./components/Auth/Login.vue";
import Signup from "./components/Auth/Signup.vue";
import Validate from "./components/Auth/Validate.vue";
import WaitingForValidation from "./components/Auth/WaitingForValidation.vue";
import POSPage from "./components/Dashboard/POSPage.vue";
import KDSPage from "./components/Dashboard/KDSPage.vue";
import WebPage from "./components/Dashboard/WebPage.vue";
import MenuManager from "./components/Menus/MenuManager.vue";
import SiteMenu from "./components/Menus/SiteMenu.vue";
import Billing from "./components/Billing/Billing.vue";
import COGS from "./components/COGS/COGS.vue";
import PasswordReset from "./components/Auth/PasswordReset.vue";
import { createStore } from "vuex";
import state from "./store/state.js";
import token from "./store/token.js";
import { createApp } from "vue";
import Notifications from "@kyvg/vue3-notification";
import { jwtDecode } from "jwt-decode";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleHalfStroke,
  faSun,
  faMoon,
  faPhone,
  faEdit,
  faPlus,
  faMinus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faMinusCircle,
  faPlusCircle,
  faEnvelope,
  faHouse,
  faPlug,
  faChartPie,
  faChartLine,
  faGears,
  faRightFromBracket,
  faMoneyBillWave,
  faCheck,
  faWifi,
  faTimes,
  faWrench,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faLink,
  faPencil,
  faFloppyDisk,
  faCaretDown,
  faCaretRight,
  faPenToSquare,
  faUtensils,
  faCashRegister,
  faArrowUpWideShort,
  faGlobe,
  faMeteor,
  faUserAstronaut,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faCircleHalfStroke,
  faSun,
  faMoon,
  faPhone,
  faEdit,
  faPlus,
  faMinus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faMinusCircle,
  faPlusCircle,
  faEnvelope,
  faHouse,
  faPlug,
  faChartPie,
  faChartLine,
  faGears,
  faRightFromBracket,
  faMoneyBillWave,
  faCheck,
  faWifi,
  faTimes,
  faWrench,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faLink,
  faPencil,
  faFloppyDisk,
  faCaretDown,
  faCaretRight,
  faPenToSquare,
  faUtensils,
  faCashRegister,
  faArrowUpWideShort,
  faGlobe,
  faMeteor,
  faUserAstronaut,
  faMagnifyingGlass
);

import { createRouter, createWebHistory } from "vue-router";
import ConfirmPassReset from "./components/Auth/ConfirmPassReset.vue";

const store = createStore({
  modules: { token: token, state: state },
});
export default store;

const router = new createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Login },
    { path: "/login", name: "Login", component: Login },
    { path: "/signup", name: "Signup", component: Signup },
    { path: "/signup/waiting-for-validation", name: "WaitingForValidation", component: WaitingForValidation },
    { path: "/billing", name: "Billing", component: Billing, meta: { requiresAuth: true } },
    { path: "/dashboard", component: DashOption, meta: { requiresAuth: true } },
    { path: "/dashopt", component: DashOption, meta: { requiresAuth: true } },
    { path: "/dashboard/:site", component: Dashboard, meta: { requiresAuth: true } },
    { path: "/pos", component: POSPage, meta: { requiresAuth: true } },
    { path: "/kds", component: KDSPage, meta: { requiresAuth: true } },
    { path: "/web", component: WebPage, meta: { requiresAuth: true } },
    { path: "/reports/:site", component: Reports, meta: { requiresAuth: true } },
    { path: "/reports/:site/:dataType", component: Reports, meta: { requiresAuth: true } },
    { path: "/reports", component: Reports, meta: { requiresAuth: true } },
    { path: "/setup/company", component: Setup, meta: { requiresAuth: true } },
    { path: "/setup/site", component: Site, meta: { requiresAuth: true } },
    { path: "/setup/station", component: Station, meta: { requiresAuth: true } },
    { path: "/validate/:token", name: "Validate", component: Validate, meta: { requiresAuth: false } },
    { path: "/settings", name: "Settings", component: Settings, meta: { requiresAuth: true } },
    { path: "/settings/:site", component: SiteAdjust, meta: { requiresAuth: true } },
    { path: "/cogs", name: "COGS", component: COGS, meta: { requiresAuth: true } },
    { path: "/menus", name: "menus", component: MenuManager, meta: { requiresAuth: true } },
    { path: "/menus/:site", name: "site-menu", component: SiteMenu, meta: { requiresAuth: true } },
    { path: "/menus/:site/:savedTab", name: "site-menu-tab", component: SiteMenu, meta: { requiresAuth: true } },
    { path: "/send-reset", name: "send-reset", component: PasswordReset, meta: { requiresAuth: false } },
    { path: "/reset/:token", name: "reset", component: ConfirmPassReset, meta: { requiresAuth: false } },
  ],
});

const isAuthenticated = async () => {
  let token = localStorage.getItem("token");
  if (token) {
    const decoded = jwtDecode(token);
    const now = parseInt(Date.now() / 1000);
    if (decoded.exp < now) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

router.beforeEach(async (to, from, next) => {
  const authenticated = await isAuthenticated();
  if (to.meta.requiresAuth) {
    if (authenticated) {
      if (!state.state.siteMapLoaded) {
        await store.dispatch("getAllData");
      }
      return next();
    }
    return next({ name: "Login" });
  }
  return next();
});

const app = createApp(App);
app
  .use(Notifications)
  .use(router)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
