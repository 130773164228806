<template>
  <Authenticated>
    <div class="container">
      <h1>{{ currentSite }} settings</h1>
      <section>
        <h2>Settings</h2>
        <DirectSettings :site="currentSite" @update-settings="update" />
      </section>
      <section>
        <h2>Manage Menu</h2>
        <SiteMenu :site="currentSite" />
      </section>
      <section v-if="floorPlan">
        <h2>Floor Plan</h2>
        <FloorPlan :site="currentSite" />
      </section>
    </div>
  </Authenticated>
</template>

<script>
import { mapGetters } from "vuex";
import DirectSettings from "../Settings/DirectSettings.vue";
import Authenticated from "../_layouts/Authenticated.vue";
import FloorPlan from "./FloorPlan.vue";
import createdMixin from "@/components/_mixins/createdMixin";
import SiteMenu from "../Menus/SiteMenu.vue";

export default {
  name: "SiteAdjust",
  mixins: [createdMixin],
  components: {
    DirectSettings,
    Authenticated,
    FloorPlan,
    SiteMenu,
  },
  data() {
    return {
      showModal: false,
      currentSite: this.$route.params.site,
      floorPlan: null,
    };
  },
  methods: {
    async update() {
      await this.$store.dispatch("getSettings");
      this.floorPlan = this.siteSettings[this.currentSite].floor_plan;
      console.log(this.floorPlan);
    },
  },
  computed: {
    ...mapGetters({
      siteSettings: "siteSettings",
      siteMap: "siteMap",
    }),
  },

  mounted() {
    this.floorPlan = this.siteSettings[this.currentSite].floor_plan;
    console.log("floorplan", this.floorPlan);
    console.log("siteSettings", this.siteSettings);
  },

  watch: {
    open() {
      if (this.open) {
        this.showModal = true;
      } else {
        this.showModal = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "public/stylesheets/wrapper";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 1rem;
  h1 {
    text-transform: capitalize;
  }
}
</style>
