import { api } from "@/helpers/api";

const state = {
  reports: [],
  companyName: "",
  siteSettings: {},
  localSettings: [],
  siteMap: [],
  channels: [],
  socket: false,
  wsListenersUp: false,
  siteMapLoaded: false,
  sites: [],
  websites: {},
  reportSite: "all",
  cogsSuppliers: [],
  cogsProducts: [],
  cogsUOM: [],
  cogsBaseRecipes: [],
  cogsMenuRecipes: [],
  cogsRecipeCategories: [],
  POSProducts: [],
  POSCategories: [],
  cogsEnabled: false,
  companySettings: {},
  timezone: "",
  gst: "",
  currency: "",
  loading: false,
};

const getters = {
  loading: (state) => state.loading,
  timezone: (state) => state.timezone,
  companySettings: (state) => state.companySettings,
  cogsEnabled: (state) => state.cogsEnabled,
  reports: (state) => state.reports,
  companyName: (state) => state.companyName,
  reportSite: (state) => state.reportSite,
  siteSettings: (state) => state.siteSettings,
  localSettings: (state) => state.localSettings,
  channels: (state) => state.socket.channels,
  socket: (state) => state.socket,
  wsListenersUp: (state) => state.wsListenersUp,
  siteMap: (state) => state.siteMap,
  siteMapLoaded: (state) => state.siteMapLoaded,
  categories: (state) => state.categories,
  sites: (state) => state.sites,
  websites: (state) => state.websites,
  cogsSuppliers: (state) => state.cogsSuppliers,
  cogsProducts: (state) => state.cogsProducts,
  cogsUOM: (state) => state.cogsUOM,
  POSProducts: (state) => (site) => {
    return state.POSProducts[site];
  },
  POSProductLookup: (state) => (lookup) => {
    let siteProducts = state.POSProducts[lookup.site];
    if (!siteProducts) {
      return null;
    }
    return Object.values(state.POSProducts[lookup.site]).find((p) => p.key === "product:" + lookup.prod);
  },
  POSCategories: (state) => (site) => {
    return state.POSCategories[site];
  },
  cogsSearchProducts: (state) => (search) => {
    return state.cogsProducts.filter((product) => {
      return product.value.escaped.toLowerCase().includes(search.toLowerCase());
    });
  },
  cogsSearchRecipes: (state) => (search) => {
    return state.cogsBaseRecipes.filter((recipe) => {
      return recipe.value.escaped.toLowerCase().includes(search.toLowerCase());
    });
  },
  cogsBaseRecipes: (state) => state.cogsBaseRecipes,
  cogsMenuRecipes: (state) => state.cogsMenuRecipes,
  cogsRecipeCategories: (state) => state.cogsRecipeCategories,
  findIngredientByID: (state) => (id) => {
    return state.cogsProducts.find((product) => product.id === id);
  },
  findRecipeByID: (state) => (id) => {
    return state.cogsBaseRecipes.find((recipe) => recipe.id === id);
  },
  cogsCostData: (state) => {
    let data = [];
    let labels = [];
    const costs = state.cogsMenuRecipes.filter((recipe) => recipe.value.cost);
    costs.map((cost) => {
      data.push(cost.value.cost);
      labels.push(cost.value.escaped);
    });
    return [data, labels];
  },
  cogsPercentageData: (state) => {
    let data = [];
    let labels = [];
    const costs = state.cogsMenuRecipes.filter((recipe) => recipe.value.percentage);
    costs.map((cost) => {
      data.push(cost.value.percentage);
      labels.push(cost.value.escaped);
    });
    return [data, labels];
  },
  settingsViaSite: (state) => (site) => {
    // console.log(Object.entries(state.siteSettings[site]));
    return Object.entries(state.siteSettings[site]);
  },
};

// actions
const actions = {
  // async getCategories({  }) {
  //   // const categories = await api.getUrl("/api/categories/get", "?include_docs=false", true);
  //   // commit("initialiseCategoriesMutation", categories.rows);
  // },

  companySettings({ commit }, companySettings) {
    commit("companySettingMutation", companySettings);
  },

  setSites({ commit }, sites) {
    commit("setSitesMutation", sites);
  },

  setReportSite({ commit }, site) {
    commit("setReportSiteMutation", site);
  },

  updateSiteMapDiff({ commit }, siteMap) {
    commit("siteMapDiffMutation", siteMap);
  },

  wsListenersUp({ commit }) {
    commit("wsListenersUpMutation");
  },

  setCompanyName({ commit }, companyName) {
    commit("companyNameMutation", companyName);
  },

  setCompanygst({ commit }, gst) {
    commit("gstMutation", gst);
  },

  setCompanyCurrency({ commit }, currency) {
    commit("currencyMutation", currency);
    console.log("currency from action:", state.currency);
  },

  initialSiteMap({ commit }) {
    commit("initialSiteMapMutation");
  },

  updateSiteMap({ commit }, siteMap) {
    commit("siteMapMutation", siteMap);
  },

  updateChannels({ commit }, channels) {
    commit("updateChannelsMutation", channels);
  },

  socketStore({ commit }, socket) {
    commit("socketStoreMutation", socket);
  },

  loading({ commit }) {
    commit("loading");
  },

  async getAllData({ commit, dispatch }) {
    await commit("companyNameMutation", window.localStorage.getItem("company"));
    await dispatch("getSettings");
    await dispatch("getPOSProducts");
    await dispatch("getPOSCategories");
    // dispatch("setSites", JSON.parse(window.localStorage.getItem("sites")));
    if (state.cogsEnabled) {
      await dispatch("getCogsSuppliers");
      await dispatch("getCogsProducts");
      await dispatch("getCogsUOM");
      await dispatch("getCogsBaseRecipes");
      await dispatch("getCogsMenuRecipes");
      await dispatch("getCogsRecipeCategories");
    }
    //await dispatch('loading');
    await commit("initialSiteMapMutation");
    state.siteMapLoaded = true;
  },

  async getSettings({ commit, dispatch }) {
    // if (!Object.keys(state.siteSettings).length) {
    const companyString = await localStorage.getItem("company");

    // const siteString = await window.localStorage.getItem("site");
    const sites = await api.get('/api/company/get_sites/"' + companyString + '"');
    const companyResults = await api.get('/api/company/get_pos/"' + companyString + '"');
    const companySettings = await api.get('/api/company/get_settings/"' + companyString + '"');
    const websites = await api.get('/api/company/get_company_websites/"' + companyString + '"');
    const settings = await companyResults.rows[0].value.settings;
    await commit("companySettingMutation", companySettings.rows[0].value);
    await window.localStorage.setItem("sites", JSON.stringify(sites.rows[0].value.sites));
    dispatch("setSites", sites.rows[0].value.sites);
    dispatch("setWebsites", websites.rows[0].value.websites);
    companySettings.rows[0].value.cogs_enable ? commit("cogsEnabledMutation", true) : commit("cogsEnabledMutation", false);
    companySettings.rows[0].value.timezone ? commit("timezoneMutation", companySettings.rows[0].value.timezone) : 0;
    //companySettings.rows[0].value.company_name ? commit("companyNameMutation", companySettings.rows[0].value.company_name) : 0;
    companySettings.rows[0].value.gst ? commit("gstMutation", companySettings.rows[0].value.gst) : 0;
    companySettings.rows[0].value.currency ? commit("currencyMutation", companySettings.rows[0].value.currency) : 0;
    // console.log("settings from getSettings", companySettings.rows[0].value);
    await commit("initialiseSiteSettingsMutation", settings);
    // }
    // await dispatch("loading");

  },

  async setWebsites({ commit }, websites) {
    await api.save_company_settings(state.companyName, "website", websites);
    commit("setWebsitesMutation", websites)
  },

  updateSettings({ commit }, settings) {
    commit("updateSiteSettingsMutation", settings[0]);
    commit("updateLocalSettingsMutation", settings[1]);
  },
  async getCogsSuppliers({ commit }) {
    const companyString = await localStorage.getItem("company");
    //console.log(companyString);
    const result = await api.get_suppliers('"' + companyString + '"');
    await commit("cogsSuppliersMutation", result);
  },

  async getCogsProducts({ commit }) {
    const companyString = await localStorage.getItem("company");
    //console.log(companyString);
    const result = await api.get_products('"' + companyString + '"');
    await commit("cogsProductsMutation", result);
  },

  async getCogsUOM({ commit }) {
    const companyString = await localStorage.getItem("company");
    //console.log(companyString);
    const result = await api.get_uom('"' + companyString + '"');
    await commit("cogsUOMMutation", result);
  },

  async getCogsBaseRecipes({ commit }) {
    const companyString = await localStorage.getItem("company");
    //console.log(companyString);
    const result = await api.get_base_recipes('"' + companyString + '"');
    await commit("cogsBaseRecipeMutation", result);
  },

  async getCogsMenuRecipes({ commit }) {
    const companyString = await localStorage.getItem("company");
    //console.log(companyString);
    const result = await api.get_menu_recipes('"' + companyString + '"');
    await commit("cogsMenuRecipeMutation", result);
  },

  async getCogsRecipeCategories({ commit }) {
    const companyString = await localStorage.getItem("company");
    //console.log(companyString);
    const result = await api.get_recipe_categories('"' + companyString + '"');
    await commit("cogsRecipeCategories", result);
  },

  async getPOSCategories({ commit }) {
    const companyString = localStorage.getItem("company");
    const sites = JSON.parse(localStorage.getItem("sites"));
    sites.map(async (site) => {
      const result = await api.get_pos_categories(companyString, site);
      await commit("POSCategoriesMutation", { site: site, result: result });
    });
  },

  async getPOSProducts({ commit }) {
    const companyString = localStorage.getItem("company");
    const sites = JSON.parse(localStorage.getItem("sites"));
    sites.map(async (site) => {
      const result = await api.get_pos_products(companyString, site);
      await commit("POSProductsMutation", { site: site, result: result });
    });
  },
};

const mutations = {
  setWebsitesMutation(state, websites) {
    state.websites = websites;
  },

  companySettingMutation(state, companySettings) {
    state.companySettings = companySettings;
  },

  cogsEnabledMutation(state, enabled) {
    state.cogsEnabled = enabled;
  },

  timezoneMutation(state, tz) {
    state.timezone = tz;
  },

  setSitesMutation(state, sites) {
    state.sites = sites;
  },

  initialiseCategoriesMutation(state, categories) {
    state.categories = categories;
  },

  loading(state) {
    state.loading = false;
  },

  setReportSiteMutation(state, site) {
    state.reportSite = site;
  },

  async initialSiteMapMutation(state) {
    const res = await api.get_stations('"' + state.companyName + '"');
    state.siteMap = res.rows[0].value;
    // state.siteMapLoaded = true;
  },

  companyNameMutation(state, companyName) {
    state.companyName = companyName;
  },

  gstMutation(state, gst) {
    state.gst = gst;
  },

  currencyMutation(state, currency) {
    state.currency = currency;
  },

  siteMapMutation(state, incomingSiteMap) {
    /* eslint-disable no-unused-vars */
    const [stationType, company, siteName] = incomingSiteMap.channel.topic.split(":");
    for (const siteMap of state.siteMap) {
      const stations = siteMap.stations[stationType];
      const presence = Object.keys(incomingSiteMap.presence);

      for (const station of stations) {
        if (presence.includes(station.name)) {
          station.active = true;
        }
      }
    }
  },

  siteMapDiffMutation(state, incomingSiteMap) {
    /* eslint-disable no-unused-vars */
    const [stationType, company, siteName] = incomingSiteMap.channel.topic.split(":");

    if (Object.keys(incomingSiteMap.presence.joins).length) {
      Object.keys(incomingSiteMap.presence.joins).forEach((join) => {
        if (join.split(":")[0] !== "admin") {
          state.siteMap.forEach((site) => {
            site.site == siteName
              ? site.stations[stationType].forEach((station) => {
                  if (station.name == join) {
                    station.active = true;
                  }
                })
              : null;
          });
        }
      });
    }

    if (Object.keys(incomingSiteMap.presence.leaves).length) {
      Object.keys(incomingSiteMap.presence.leaves).forEach((leave) => {
        if (leave.split(":")[0] !== "admin") {
          state.siteMap.forEach((site) => {
            site.site == siteName
              ? site.stations[stationType].forEach((station) => {
                  if (station.name == leave) {
                    station.active = false;
                  }
                })
              : null;
          });
        }
      });
    }
  },

  initialiseSiteSettingsMutation(state, settings) {
    state.siteSettings = settings;
  },

  updateSiteSettingsMutation(state, siteSettings) {
    state.siteSettings = siteSettings;
  },

  updateChannelsMutation(state, channels) {
    state.channels = channels;
  },

  socketStoreMutation(state, socket) {
    state.socket = socket;
  },

  wsListenersUpMutation(state) {
    state.wsListenersUp = !state.wsListenersUp;
  },

  cogsSuppliersMutation(state, suppliers) {
    state.cogsSuppliers = suppliers;
  },

  cogsProductsMutation(state, products) {
    state.cogsProducts = products;
  },

  cogsUOMMutation(state, uoms) {
    state.cogsUOM = uoms;
  },

  cogsBaseRecipeMutation(state, cogsBaseRecipes) {
    state.cogsBaseRecipes = cogsBaseRecipes;
  },

  cogsMenuRecipeMutation(state, cogsMenuRecipes) {
    state.cogsMenuRecipes = cogsMenuRecipes;
  },

  cogsRecipeCategories(state, cogsRecipeCategories) {
    state.cogsRecipeCategories = cogsRecipeCategories;
  },

  POSCategoriesMutation(state, f) {
    state.POSCategories[f.site] = f.result;
  },

  POSProductsMutation(state, h) {
    state.POSProducts[h.site] = h.result;
  },
};

const stateModule = {
  state,
  strict: true,
  getters,
  actions,
  mutations,
};

export default stateModule;