<template>
  <input type="checkbox" :id="modelName" :value="modelValue" :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)" />
  <label v-if="onLabel != '' && modelValue" :for="modelName">{{ onLabel }}</label>
  <label v-else-if="offLabel != '' && !modelValue" :for="modelName">{{ offLabel }}</label>
  <label class="no-label" v-else :for="modelName"></label>
</template>

<script>
export default {
  name: "ToggleSwitch",

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    modelName: {
      type: String,
      required: true,
    },
    onLabel: {
      type: String,
      default: "",
      required: false,
    },
    offLabel: {
      type: String,
      default: "",
      required: false,
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 50px;
  margin: 10px 1rem;
  background: grey;
  border-radius: 100px;
  position: relative;
  font-size: larger;
  padding: 0 25px;
}

label.no-label {
  min-width: 75px !important;
  height: 40px !important;
}

label.no-label:after {
  width: 35px !important;
  height: 35px !important;
}

label:after {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 45px;
  height: 45px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  background: var(--action-colour);
}

input:checked + label:after {
  left: calc(100% - 2.5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 50px;
  height: 50px;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media only screen and (orientation: portrait) {
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  input:checked + label:after {
    transform: translate(-100%);
  }

  label {
    width: 70px;
    height: 40px;
  }

  label.no-label {
    min-width: 75px !important;
    height: 26px !important;
  }

  label.no-label:after {
    width: 21px !important;
    height: 21px !important;
  }

  label:active:after {
    width: 40px;
    height: 40px;
  }

  label:after {
    width: 21px;
    height: 21px;
  }
}
</style>
